import { Link } from "react-router-dom";

import "./header.scss";

//graphic assets
import home from "../../assets/icons/temp/home.png";
import projects from "../../assets/icons/temp/hammerNscrew.png";
import about from "../../assets/icons/temp/avatar.png";
import skills from "../../assets/icons/temp/gears.png";
import resume from "../../assets/icons/temp/Vector-Resume-PNG-HD-744128732.png";
import contact from "../../assets/icons/temp/email.png";
import linkedin from "../../assets/icons/temp/Black-linkedin-logo-design-on-transparent-background-PNG.png";
import github from "../../assets/icons/temp/github.png";

function Header() {
  const resumePdf =
    "https://drive.google.com/file/d/1FhpSHyCvXfaiSJHqoWX5nbn9yx2w90S9/view?usp=sharing";

  const openResumePdf = () => {
    window.open(resumePdf, "_blank");
  };

  return (
    <>
      {/*icons!!!*/}

      <div className="header__container">
        <Link to="/" className="header__logo">
          <p className="header__logo">KC</p>
        </Link>
        <ul className="header__nav">
          <li>
            <Link to="/projects">
              <img src={projects} className="header__icon" alt="projects" />
            </Link>
          </li>
          <li>
            <Link to="/about">
              <img src={about} className="header__icon" alt="about" />
            </Link>
          </li>
          <li>
            <Link onClick={openResumePdf}>
              <img src={resume} className="header__icon" alt="resume" />
            </Link>
          </li>
          {/*
          <li>
            <Link to="/contact">
              <img src={contact} className="header__icon" />
            </Link>
          </li>
          */}
          <li>
            <Link to="https://www.linkedin.com/in/konrad-czulowski/">
              <img
                src={linkedin}
                className="header__icon header__iconHide"
                alt="linkedin logo"
              />
            </Link>
          </li>
          <li>
            <Link to="https://github.com/Konirad">
              <img
                src={github}
                className="header__icon header__iconHide"
                alt="github logo"
              />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
