import "./recentActivity.scss";

function RecentActivity() {
  return (
    <>
      <div className="recentActivity__container">
        <p className="recentActivity__header">[Since 2022]</p>
        <div className="recentActivity__itemBox">
          <p className="recentActivity__itemNumber">(1)</p>
          <p className="recentActivity__text">
            Experienced media director with vast experience in post-production
            editing, file management, and workflows.
          </p>
        </div>
        <div className="recentActivity__itemBox">
          <p className="recentActivity__itemNumber">(2)</p>
          <p className="recentActivity__text">
            Graduated from diploma boot camp with honors, building proficiency
            in JavaScript, React, Git, and more.
          </p>
        </div>
        <div className="recentActivity__itemBox">
          <p className="recentActivity__itemNumber">(3)</p>
          <p className="recentActivity__text">
            Working with clients to build engaging online experiences that are
            on brand and tailored.
          </p>
        </div>
      </div>
    </>
  );
}

export default RecentActivity;
