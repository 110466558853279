import "./aboutPage.scss";
import konradHeadshot1 from "../../assets/pictures/konradHeadshot1horz.jpg";

//icons
import jsLogo from "../../assets/icons/temp/javaScript-logo.png";
import reactLogo from "../../assets/icons/temp//React.webp";
import githubLogo from "../../assets/icons/temp/github.png";
import expressLogo from "../../assets/icons/temp/express-js.png";
import nodeLogo from "../../assets/icons/temp/nodejs-logo-D26404F360-seeklogo.com.png";
import sassLogo from "../../assets/icons/temp/sass.png";
import axiosLogo from "../../assets/icons/temp/axios.png";
import typescriptLogo from "../../assets/icons/temp/Typescript_logo_2020.svg.png";
import mongodbLogo from "../../assets/icons/temp/mongodb_original_wordmark_logo_icon_146425.png";
import mysqlLogo from "../../assets/icons/temp/mySql logo.png";
import html5Logo from "../../assets/icons/temp/html5logo.webp";
import css3Logo from "../../assets/icons/temp/css3logo.webp";

function AboutPage() {
  document.title = "about";

  return (
    <>
      <div className="about__container">
        <div className="about__quickview">
          <h2 className="about__header">Hi there, I'm Konrad</h2>
          <div className="about__introBox">
            <div>
              <h2 className="about__headerDesktop">Hi there, I'm Konrad</h2>
              <img src={konradHeadshot1} className="about__headshot" />
            </div>
            <div className="about__toolsBox">
              <h3 className="about__toolsHeader">TOOLS</h3>
              <div className="about__toolsImg">
                <img src={jsLogo} className="about__toolsIcons" />
                <img src={reactLogo} className="about__toolsIcons" />
                <img src={githubLogo} className="about__toolsIcons" />
                <img src={expressLogo} className="about__toolsIcons" />
                <img src={nodeLogo} className="about__toolsIcons" />
                <img src={sassLogo} className="about__toolsIcons" />
                <img src={axiosLogo} className="about__toolsIcons" />
                <img src={mongodbLogo} className="about__toolsIcons" />
                <img src={mysqlLogo} className="about__toolsIcons" />
                <img src={typescriptLogo} className="about__toolsIcons" />
                <img src={html5Logo} className="about__toolsIcons" />
                <img src={css3Logo} className="about__toolsIcons" />
              </div>
            </div>
          </div>
        </div>
        <div className="about__textBox">
          <p className="about__text">
            <span className="about__indent">
              Welcome to my corner of the web!{" "}
            </span>
            I'm currently based in the vibrant city of Toronto, where I'm diving
            headfirst into the world of freelance front-end development while
            nurturing my passion for crafting side projects in my spare time.
          </p>
          <p className="about__text">
            <span className="about__indent">Over the past year,</span> I dove
            into the realm of software engineering, honing my skills at a
            bootcamp where I delved deep into languages like HTML, CSS, and
            JavaScript. In collaborative environments where I sharpened my
            project management skills using tools like Git and GitHub,
            collaborating seamlessly with teams of fellow developers. Along the
            way, I've embraced a slew of powerful libraries through npm, from
            React to Express, as well as fine-tuning my design collaboration
            skills in Figma. Currently, I'm adding the finishing touches to my
            Node.js and MySQL prowess by crafting a thrifting platform.
          </p>
          <p className="about__text">
            <span className="about__indent">
              Before venturing into the world of coding,
            </span>
            I wore a different hat as a video director and producer, leveraging
            my knack for computers and creativity to bring visions to life. From
            building and customizing rigs to wielding software like Adobe Suite,
            DaVinci Resolve, and After Effects, I've been the architect behind
            captivating visual narratives. Whether it was directing talent,
            organizing shoots, or managing social media campaigns, I thrived on
            the dynamic challenges of bringing businesses and startups into the
            spotlight.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
