import fittstoryBg from "../../assets/pictures/sites/fittStory.jpg";
import neoasisBg from "../../assets/pictures/sites/neoasis.png";
import smartStretchBg from "../../assets/pictures/sites/smartStretch.png";
import instockBg from "../../assets/pictures/sites/instock.png";

import "./pastWork.scss";

import { Link } from "react-router-dom";

function PastWork() {
  return (
    <>
      <div className="pastWork">
        <p className="pastWork__header">[MY RECENT PROFESSIONAL WORK]</p>

        <Link to={"/projects/fittstory"} className="pastWork__fittStoryBox">
          <p>(01)</p>
          <div className="pastWork__box">
            <img src={fittstoryBg} className="pastWork__img" />
            <p className="pastWork__title">
              FittStory{" "}
              <span className="pastWork__inProgress">(in development)</span>
            </p>
            <p>Full-Stack Developer</p>
            <p className="pastWork__desc">
              A platform for thrifting and trading with like-minded
              environmentally-friendly individuals.
            </p>
          </div>
        </Link>

        <Link to={"/projects/neoasis"} className="pastWork__neoasisBox">
          <p>(02)</p>
          <div className="pastWork__box">
            <img src={neoasisBg} className="pastWork__img" />
            <p className="pastWork__title">Neoasis</p>
            <p>Front-end Developer</p>
            <p className="pastWork__desc">
              A website for a board game development company, engaging in a
              collaborative partnership with an external designer.
            </p>
          </div>
        </Link>

        <Link
          to={"/projects/smartstretch"}
          className="pastWork__smartStretchBox"
        >
          <p>(03)</p>
          <div className="pastWork__box">
            <img src={smartStretchBg} className="pastWork__img" />
            <p className="pastWork__title">SmartStretch</p>
            <p>Front-end Developer</p>
            <p className="pastWork__desc">
              Stretch wrap distribution company, collaborating closely with an
              in-house designer to seamlessly blend the company's vision.
            </p>
          </div>
        </Link>

        <Link to={"/projects/instock"} className="pastWork__inStockBox">
          <p>(04)</p>
          <div className="pastWork__box">
            <img src={instockBg} className="pastWork__img" />
            <p className="pastWork__title">InStock</p>
            <p>Full-stack Developer</p>
            <p className="pastWork__desc">
              Worked in a dynamic team of six, developing a robust system for
              tracking warehouse inventory and updating stock counts.
            </p>
          </div>
        </Link>
      </div>
    </>
  );
}

export default PastWork;
