import { Link, useParams } from "react-router-dom";
import "./pastProj.scss";

//data mock file
import projData from "../../Data/pastProj.json";

//image assets
import fittstoryImg from "../../assets/pictures/sites/fittStory.jpg";
import neoasisImg from "../../assets/pictures/sites/neoasis.png";
import instockImg from "../../assets/pictures/sites/instock.png";
import smartstretchImg from "../../assets/pictures/sites/smartStretch.png";

function PastProj() {
  const { projectName } = useParams();

  const getProject = projData.filter((item) => projectName === item.identifier);

  const { siteName, siteDescA, siteDescB, supportImg, identifier } =
    getProject[0];

  let currentPageImgSrc;
  let pageScssClass;
  let extLink;
  let visability;

  document.title = identifier;

  switch (identifier) {
    case "fittstory":
      currentPageImgSrc = fittstoryImg;
      pageScssClass = "fittstoryClass";
      extLink =
        "https://www.rookiemag.com/wp-content/uploads/2012/03/1330145876IMG_7565.jpg";
      visability = "btnAbscent";
      break;

    case "neoasis":
      currentPageImgSrc = neoasisImg;
      pageScssClass = "neoasisClass";
      extLink = "https://neoasisgames.netlify.app/";
      break;

    case "smartstretch":
      currentPageImgSrc = smartstretchImg;
      pageScssClass = "smartstretchClass";
      extLink = "https://smartstretchghana.com/";
      break;

    case "instock":
      currentPageImgSrc = instockImg;
      pageScssClass = "instockClass";
      extLink = "https://github.com/Konirad/instock-frontend";
      break;

    default:
      currentPageImgSrc =
        "https://user-images.githubusercontent.com/1124253/39808691-002c6072-53c3-11e8-9097-d019d5971d91.png";
  }

  return (
    <>
      <div className="pastProj__container">
        <div className="pastProj__textContainer">
          <h2 className={`pastProj__header ${pageScssClass}`}>{siteName}</h2>
          <p className="pastProj__description">{siteDescA}</p>
          <p className="pastProj__description">{siteDescB}</p>
          {/*<p>Technologies used?</p>*/}
        </div>
        <div className="pastProj__imgContainer">
          <img
            className={`pastProj__img ${pageScssClass}`}
            src={currentPageImgSrc}
          />

          <Link to={extLink}>
            <div
              className={`pastProj__visitSite ${pageScssClass} ${visability}`}
            >
              VISIT SITE
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PastProj;
