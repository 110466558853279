import Hero from "../../components/hero/Hero";
import RecentActivity from "../../components/recentActivity/RecentActivity";
import PastExp from "../../components/pastExp/PastExp";
import PastWork from "../../components/pastWork/PastWork";
import "./homePage.scss";
import { Link } from "react-router-dom";

function HomePage() {
  document.title = "home";

  return (
    <>
      <div className="homePage">
        <Hero />
        <div className="homePage__projectsContainer">
          <p className="homePage__projectsTitle">[Recent Projects]</p>
          <div className="homePage__projectsBtns">
            <Link to={"/projects/neoasis"}>
              <a class="btn41-43 btn-42 neo">Neoasis</a>
            </Link>
            <Link to={"/projects/smartstretch"}>
              <a class="btn41-43 btn-42 smart">SmartStretch</a>
            </Link>
            <Link to={"/projects/instock"}>
              <a class="btn41-43 btn-42 stock">InStock</a>
            </Link>
          </div>
          <div className="homePage__bioMobile">
            <p>
              Hi there, I’m Konrad, a <span className="hero__bioRole">web developer</span> with a
              passion for crafting engaging and efficient web applications.
            </p>

            <p>
              From brainstorming innovative concepts to fine-tuning the user
              experience, I thrive on the challenges that come with creating
              dynamic online experiences.{" "}
            </p>

            <p>
              I’m dedicated to building web applications that not only meet but
              exceed expectations.{" "}
            </p>

            <p>Let’s collaborate</p>
          </div>
        </div>
        <RecentActivity />
        <PastExp />
        <PastWork />
      </div>
    </>
  );
}

export default HomePage;
