import "./pastExp.scss";

function PastExp() {
  return (
    <>
      <div className="pastExp">
        <div className="pastExp__titleBox">
          <p>[ PAST EXPERINCE</p>
          <p>2021-PRESENT ]</p>
        </div>
        <div>
          <div className="pastExp__positionBox">
            <div className="pastExp__info">
              <p className="pastExp__year">2024</p>
              <p className="pastExp__company">Freelance</p>
            </div>
            <p className="pastExp__role">Full-stack web developer</p>
          </div>
          <div className="pastExp__positionBox">
            <div className="pastExp__info">
              <p className="pastExp__year">2023</p>
              <p className="pastExp__company">BrainStation</p>
            </div>
            <p className="pastExp__role">Software Engineering Diploma</p>
          </div>
          <div className="pastExp__positionBox">
            <div className="pastExp__info">
              <p className="pastExp__year">2022</p>
              <p className="pastExp__company">SuperHairPieces</p>
            </div>
            <p className="pastExp__role">Video Editor & Director</p>
          </div>
          <div className="pastExp__positionBox">
            <div className="pastExp__info">
              <p className="pastExp__year">2021</p>
              <p className="pastExp__company">Kabo</p>
            </div>
            <p className="pastExp__role">Video & Creative Lead</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PastExp;
