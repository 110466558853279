import "./hero.scss";

import konradHeadshot1 from "../../assets/pictures/PXL_20240422_235554853.3.jpg";

function Hero() {
  return (
    <>
      <div className="hero__nameNtitle">
        <p className="hero__name">Konrad Czulowski</p>
        {/*<p className="hero__title">Specializing in web applications</p>
        <p className="hero__location">Based in Toronto</p>
        */}
      </div>
      <div className="hero__bioBox">
        <img src={konradHeadshot1} className="hero__headShot" />
        <div className="hero__bioText">
          <p>
            Hi there, I’m Konrad, a  <span className="hero__bioRole">web developer</span> with a passion
            for crafting engaging and efficient web applications.
          </p>

          <p>
            From brainstorming innovative concepts to fine-tuning the user
            experience, I thrive on the challenges that come with creating
            dynamic online experiences.{" "}
          </p>

          <p>
            I’m dedicated to building web applications that not only meet but
            exceed expectations.{" "}
          </p>

          <p>Let’s collaborate</p>
        </div>
      </div>
    </>
  );
}

export default Hero;
