function MailTo() {
  return (
    <>
      <a href="mailto:konrad.czulowski@gmail.com" className="footer__email">
        KONRAD.CZULOWSKI@GMAIL.COM
      </a>
    </>
  );
}

export default MailTo;
