import { Link } from "react-router-dom";
import "./footer.scss";

import MailTo from "../mailTo/MailTo";

function Footer() {
  const resumePdf =
    "https://drive.google.com/file/d/19x-P-phPV7JpINdLOoU7hoyOmpV2GJou/view?usp=sharing";

  const openResume = () => {
    window.open(resumePdf, "_blank");
  };

  return (
    <>
      <div className="footer">
        <MailTo />
        <div className="footer__desktop">
          <div>
            <p className="footer__header">Location</p>
            <p className="footer__location">Toronto, ON, Canada</p>
          </div>
          <menu className="footer__lists">
            <div>
              <p className="footer__header">SOCIALS</p>
              <ul className="footer__socialsList">
                <Link
                  to="https://www.linkedin.com/in/konrad-czulowski/"
                  className="footer__listItem"
                >
                  <li>LinkedIn</li>
                </Link>
                <Link
                  to="https://github.com/Konirad"
                  className="footer__listItem"
                >
                  <li>Github</li>
                </Link>
              </ul>
            </div>
            <div>
              <p className="footer__header">NAV</p>
              <ul className="footer__linksList">
                <Link to={"/projects"} className="footer__listItem">
                  <li>Projects</li>
                </Link>
                <Link to={"/about"} className="footer__listItem">
                  <li>About</li>
                </Link>
                <Link className="footer__listItem" onClick={openResume}>
                  <li>Resume</li>
                </Link>
                {/*
                <Link to={"/contact"} className="footer__listItem">
                  <li>Contact</li>
                </Link>
                 */}
              </ul>
            </div>
          </menu>
        </div>
      </div>
    </>
  );
}

export default Footer;
