import { useParams } from "react-router-dom";

import PastWork from "../../components/pastWork/PastWork";

function ProjectsPage() {
  document.title = "past work";

  return (
    <>
      <PastWork />
    </>
  );
}

export default ProjectsPage;
